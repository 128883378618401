body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.menu {
  background-color: #002d5b;
}

.navbar-logo{
    height:30px;
    width:auto;
}

.tp-col {
   padding-top: 10px;
 }

 .tp-col-l {
   padding-top: 20px;
 }

 .tp-col-bl {
   padding-top: 40px;
 }
