.logo {
  height: auto;
  width: 80%;
}
.next-button {
  width: 50%;
}

.logo-column {
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.next-column {
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
